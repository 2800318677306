.era-attributes .q-card {
  margin-bottom: 1rem;
}
.era-attributes .q-card .q-card-container {
  padding: 0.5rem;
}
.era-attributes .q-card .q-card-title {
  line-height: 1rem;
}
.eligiblity-toggle >>> .q-option-label {
  color: #757575;
}
/*# sourceMappingURL=src/components/era/BasicEraInfo.css.map */