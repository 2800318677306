@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'










































































































































































































.eras-table
  >>> th
    border-bottom 1px solid #888

.term-title-condensed
  font-weight 500
  text-transform uppercase
