.additional-info
  margin-top 10px

  div
    display flex
    align-items flex-start

.info-label
  @extend .term-title-small
  display inline-block
  margin-right 5px
  line-height 1.8
  text-transform uppercase
  text-align right
