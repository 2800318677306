@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'































































































































































































































































































































































































































































































































































































































.field-groups {
  & > .q-field {
    margin-top: 20px;
  }
}

.block.inset {
  margin-left: -30px;
}
