.portfolio-name-edit {
  display: none;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  font-size: 24px;
}
.portfolio-name-btn {
  display: none;
  font-size: 12px !important;
  z-index: 1;
}
.portfolio-name-btn:first-of-type {
  margin-left: 10px;
}
.portfolio-name-progress {
  display: none;
  margin-left: 10px;
  color: #c6007e;
  font-size: 12px;
  z-index: 1;
}
.editing .portfolio-name-display {
  opacity: 0;
}
.editing .portfolio-name-edit {
  display: flex;
}
.editing .portfolio-name-btn {
  display: inline-block;
}
.saving .portfolio-name-btn {
  display: none;
}
.saving .portfolio-name-progress {
  display: inline;
}
.portfolio-nav {
  height: 34px;
}
.dialog-body {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
/*# sourceMappingURL=src/components/portfolio/details/PortfolioOverview.css.map */