.researcher-banner {
  background: -webkit-linear-gradient(left, #6d0020 0%, #a00d29 21%, #d71945 57%, #bb0a7f 78%, #77005e 98%, #76005e 100%);
  background: -o-linear-gradient(left, #6d0020 0%, #a00d29 21%, #d71945 57%, #bb0a7f 78%, #77005e 98%, #76005e 100%);
  background: -ms-linear-gradient(left, #6d0020 0%, #a00d29 21%, #d71945 57%, #bb0a7f 78%, #77005e 98%, #76005e 100%);
  background: -moz-linear-gradient(left, #6d0020 0%, #a00d29 21%, #d71945 57%, #bb0a7f 78%, #77005e 98%, #76005e 100%);
  background: linear-gradient(to right, #6d0020 0%, #a00d29 21%, #d71945 57%, #bb0a7f 78%, #77005e 98%, #76005e 100%);
}
.search-researcher-link {
  text-decoration: none;
}
.search-researcher-link .q-chip:hover {
  transform: scale(1.05);
}
/*# sourceMappingURL=src/components/researcher/researcher-details/ResearcherBanner.css.map */