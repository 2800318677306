.r-collapsible-container.collapsible-theme-mq-soft
  .q-collapsible-inner
    .q-item
      color $mq-soft
      border-bottom 1px solid $mq-grey-dark
  .q-collapsible-sub-item
    background-color $mq-soft-light
    border-bottom 1px solid $mq-grey-dark
    padding-top $radar-general-padding

.q-collapsible
  font-size 14px
  font-weight 500
  font-style normal
  line-height 1.29
  letter-spacing 0px
