.menu-icon,
.menu-text {
  color: rgba(0,0,0,0.87);
}
.q-item.router-link-exact-active {
  background-color: #80225f;
  color: #fff;
}
.q-item.router-link-exact-active .menu-icon,
.q-item.router-link-exact-active .menu-text {
  color: #fff;
}
/*# sourceMappingURL=src/components/side-nav-bar/SideNavLink.css.map */