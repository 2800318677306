//quasar card overrides
.q-card-container
  padding 10px
//
//.q-card-title
//  font-size 14px
//  font-weight 400
//  letter-spacing normal
//  line-height 1.8rem
//  &:empty
//    display none

.q-card-container.small
  padding .2rem

  .q-card-title
    font-size 14px
    line-height 1.2rem

  .q-card-subtitle
    font-size 10px
    .q-icon
      font-size 19px

.q-card-subtitle, .q-card-title-extra
  font-size 12px
  .q-icon
    font-size 21px

.q-card-main
  font-size 12px
.q-card-main.small
  padding-bottom .2rem

.q-card-primary + .q-card-main
  padding-top 0

.q-card-actions
  padding 6px
  .q-btn
    padding 0 6px

.q-card-actions-horiz
  .q-btn + .q-btn
    margin-left 6px

.q-card-actions-vert
  .q-btn + .q-btn
    margin-top 2px
