.additional-info {
  display: grid;
  grid-template-columns: 100px 1fr;
  padding: 5px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
}
.child-eligibility {
  align-items: center;
}
.child-eligibility >>> .q-toggle-handle {
  align-items: center;
  background-color: #e5e5e5;
}
.child-eligibility >>> .q-option-label {
  display: block;
  margin: 5px 0 0 0;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #9e9e9e;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  opacity: 1;
  transition: background-color 0.5s ease-out;
}
.child-eligibility >>> .active + .q-option-label {
  background-color: #2e7d32;
}
.child-eligibility .q-toggle {
  display: block;
  margin-left: 20px;
}
.child-eligibility .q-toggle[tabindex="-1"] {
  cursor: default !important;
}
.child-eligibility .q-toggle[tabindex="-1"] >>> .q-option-inner {
  display: none;
}
.child-eligibility .q-toggle.is-changed >>> .q-option-label {
  background-color: #ef6c00;
}
.info-label {
  min-width: 60px;
}
/*# sourceMappingURL=src/components/applied-measure/AppliedMeasureMetadataCell.css.map */