@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'




























































































































































































































.no-portfolios
  display flex
  flex-direction column
  justify-content center
  align-items center
  min-height 40vh
.p-name
  flex-basis 60%
  max-width 60%
  width 100%

.p-claims
  display flex
  flex-basis 40%

  span
    flex-basis 33%
    text-align center

.p-header
  position sticky
  top 0
  display flex
  align-items center
  padding 20px 10px 20px 68px
  background-color #fff
  border-bottom 1px solid
  z-index 1

  .p-claims
    justify-content center

.p-item
  display flex
  align-items center
  padding 0 10px
  min-height 56px

  &:nth-of-type(2n+1)
    background-color rgba(0,0,0, .1)

  a
    display flex
    align-self: stretch;
    align-items: center;
    width 100%

    &:link
      text-decoration none

    &:hover span
      font-weight 700
      text-decoration underline

  .p-name
    color $mq-purple

  .p-claims
    font-size 14px

.layout-padding pre
  font-family $typography-font-family

.portfolio-nav
  height 34px

