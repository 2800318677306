@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'









































































































































































.headline-title
  position sticky
  top 0
  padding-left 20px
  padding-bottom 1rem
  background-color $mq-sand
  z-index 1

.position-title
  position sticky
  top 44px
  margin-left -20px
  padding-left 20px
  padding-bottom 15px
  background-color darken($mq-sand, 7.5%)
  border-top 2px solid rgba(0,0,0, .2)
  overflow-y auto
  overflow-x hidden

.is-primary
  margin-top 5px
  margin-left 5px
  border-radius 3px

dl
  margin-bottom .5rem

dd
  margin-bottom .5rem

dt
  text-transform uppercase
  margin-top 1rem

.q-toggle
  margin-bottom 5px

  >>> .q-option-label
    opacity 0
    transition none

  >>> .active + .q-option-label
    padding 2px 6px
    border-radius 4px
    background-color $green-9
    color #fff
    font-size 12px
    text-transform uppercase
    opacity 1
    transition opacity .4s ease-in-out
    cursor default

