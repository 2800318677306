@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'
















































$stats-item
  color black
  text-decoration none

  & > div
    padding 6px 0
    border-radius 4px

    &:not(.no-link):hover
      background-color $indigo-2
      font-weight 700

    &.no-link:hover
      cursor not-allowed

    div:last-child
      padding-top 5px
      color $mq-magenta

.am-stats
  &__container
    display flex
    flex-wrap wrap
    margin-top 10px
    border 1px solid rgba(0,0,0,.2)
    border-radius 6px
    font-size 12px
    text-align center
    text-transform uppercase
    overflow hidden

  &__total
    flex-basis 50px
    padding 4px
    @extend $stats-item
