// drawer overrides
body.desktop
  .q-layout-drawer-mini .q-list-header:not(:first-child) + .q-item
    margin-top 40px

// modal overrides
.modal-header
  color $primary

.q-popover .q-item-sublabel
  max-width 600px !important

// chip overrides
.q-chip-dense .q-chip-main
  padding 1px 4px

// tooltip overrides
.q-tooltip
  padding 6px
  background #fefefe
  color: #222
  border 1px solid rgba(0,0,0,.15)
  font-size 11.5px
  box-shadow 0 1px 3px rgba(0,0,0,0.2), 0 1px 1px rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12)

// btn override
.q-btn
  &.disabled
    opacity .5 !important
