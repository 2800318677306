.apportion-row {
  background-color: #f4f2ef;
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}
.event-icon-wrapper {
  height: 58px;
  width: 58px;
  border: 1px solid;
  border-radius: 50%;
  background-color: #424b69;
}
.event-icon {
  font-size: 24px;
  color: #fff;
}
/*# sourceMappingURL=src/components/research-output/claims-and-escalations/claim-events/PortfolioEvent.css.map */