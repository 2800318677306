.apportion-row {
  background-color: #f4f2ef;
/*padding-bottom 27.5px*/
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}
.comment-row {
  padding-top: 16px;
}
.forc-selector {
  min-width: 140px;
  font-size: 16px;
}
.apportion {
  min-width: 83px;
  font-size: 16px;
}
.event-icon-wrapper {
  height: 58px;
  width: 58px;
  border: 1px solid;
  border-radius: 50%;
  background-color: #424b69;
}
.event-icon {
  font-size: 24px;
  color: #fff;
}
.partial_resolution .event-icon,
.complete_resolution .event-icon {
  border: 1px solid;
  padding: 6px;
  border-radius: 50%;
  background: #fff;
  color: #424b69;
}
.partial_resolution .event-icon {
  background: linear-gradient(90deg, #c3ccd7 50%, #fff, 50%) !important;
}
/*# sourceMappingURL=src/components/research-output/claims-and-escalations/claim-events/DisputeEvent.css.map */