@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'








































































































































































































































































































































































.portfolio-name
  &-edit
    display none
    position absolute !important
    top 0
    left 0
    right 0
    font-size 24px

  &-btn
    display none
    font-size 12px !important
    z-index 1

    &:first-of-type
      margin-left 10px

  &-progress
    display none
    margin-left 10px
    color $mq-magenta
    font-size 12px
    z-index 1

.editing
  .portfolio-name
    &-display
      opacity 0

    &-edit
      display flex

    &-btn
      display inline-block

.saving
  .portfolio-name
    &-btn
      display none

    &-progress
      display inline

.portfolio-nav
  height 34px

.dialog-body
  display flex
  align-items flex-start
  line-height 1.4
