@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'
































































































































































































.body-2
  font-weight 500

.page-body-height
  height calc(100vh - 68px)

.page-body-height-minus-navigation
  height calc(100vh - 134px)

.search-bar
  max-width 220px

.search-query
  min-width 200px
