.forc-selector-min {
  width: 4rem;
}
.apportion-min {
  width: 4rem;
}
.bulk-claim-wrapper,
.bulk-error-wrapper {
  width: 637px;
}
.bulk-error-wrapper > div {
  margin-right: 37px;
}
.bulk-claim-wrapper {
  min-height: 57px;
}
.authors-wrapper {
  min-height: 2rem;
  max-width: 1px;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  border: 1px solid #ff0;
}
.term-title-condensed {
  min-width: 102px !important;
}
.bulk-code-table >>> table {
  table-layout: fixed;
}
.bulk-code-table >>> .q-table-top:empty {
  display: none;
}
.bulk-code-table.fullscreen >>> .q-table-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  background: #fff;
  z-index: 910;
}
.bulk-code-table.fullscreen >>> .q-table-middle {
  padding-top: 147px;
}
.bulk-code-table.fullscreen >>> .q-table-middle th {
  top: 147px;
}
/*# sourceMappingURL=src/components/bulk-code/BulkCodeList.css.map */