@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'


























































.report-table
  min-width max-content

  >>> table
    position relative

  >>> .scroll
    overflow visible

  >>> th,
  >>> td
    padding 2px 10px
    border-color #ccc

    @media (min-width 768px) {
      height 40px
    }

  >>> thead
    tr
      height 40px

    th
      position sticky
      top 0
      border-left-width 1px
      background-color $mq-sand-dark
      color #222
      font-size 13px
      text-transform uppercase
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      z-index 1

      &:first-child
        border-left-width 0

      /.collapsible-report &
        top 65px

      /.q-collapsible-opened div[style^=overflow-y] &,
      /.q-collapsible-closed &
        top auto

  >>> tbody
    tr:nth-child(odd) td
      background-color transparentify(#eee, #fff, .05)

    tr td:empty
      background-color #aaa

  &.has-summary-row
    >>> tbody tr:last-child td
      font-weight 700

.empty-table-data
  display flex
  justify-content center
  align-items center
  padding 20px
