@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'











































































































.search-bar
  border-left 1px solid $tertiary

.mini-search-title
  word-break break-all
  width 1em

.mini
  width 60px

.full
  width 400px

.container
  margin-top $header-and-breadcrumb-height

