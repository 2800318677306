.forc-selector-min {
  width: 4rem;
}
.apportion-min {
  width: 4rem;
}
.bulk-claim-wrapper {
  width: 866px;
}
.authors-wrapper {
  min-height: 2rem;
  max-width: 1px;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  border: 1px solid #ff0;
}
.what-if-claim-viewer {
  width: 181.5px;
  height: 36px;
  border: solid 1px #6b7c93;
  background-color: #6b7c93;
}
/*# sourceMappingURL=src/components/analytics/scenario/editor/WhatIfEditableCell.css.map */