@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'







































.status-container
  display flex
  gap 10px
  padding: 5px 10px
  border-radius: 14px

.valid
  background-color #659d4e

.under
  background-color #066ba0

.over
  background-color #802243

.claim_count_error
  background-color $primary

.no_claims
  background-color #a79c78

.portfolio_member
  background-color #606a8b

.ineligible
  background-color #4e4e4e

.clawback_invalid
  background-color #cd4646
