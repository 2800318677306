.home-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background: linear-gradient(90deg, #eee 0%, #6b7c93 70%);
}
.mq-logo {
  width: clamp(50px, 80vw, 300px);
  height: 100%;
}
.radar-logo {
  width: 120px;
  color: #fff;
  cursor: pointer;
}
.home__announcements {
  background-color: rgba(198,0,126,0.2);
  border: 2px solid rgba(198,0,126,0.2);
  border-radius: 6px;
}
.home__announcements span {
  color: #76232f;
}
.home__section {
  box-shadow: none;
}
.home__section + .home__section {
  margin-top: 30px;
}
.home__section >>> .q-card-title {
  color: #000;
  font-size: 20px;
}
.home__section >>> .q-item,
.home__section >>> .q-card-main {
  color: #546e7a;
  font-size: 14px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/components/Home.css.map */