.collapsible-report {
  min-width: max-content;
}
.collapsible-report /deep/ > .q-collapsible-inner > .q-item {
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  height: 45px;
  background-color: #e0e0e0;
  z-index: 2;
}
.gap {
  order: 2;
  flex-basis: calc(100% + 28px);
  margin: 5px -14px 0;
  height: 20px;
  background-color: #fff;
}
.group-title {
  font-size: 18px;
}
.report-buttons {
  flex-grow: 1;
  order: 1;
  text-align: right;
  transition: opacity 0.3s linear;
}
.q-collapsible-closed .report-buttons {
  opacity: 0;
}
.active-button {
  background-color: #bdbdbd;
  box-shadow: inset 0 3px 5px rgba(0,0,0,0.1);
}
/*# sourceMappingURL=src/components/reporting/common/ReportContainer.css.map */