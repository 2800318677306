.portfolio-claim > label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.9px;
  color: #424b69;
}
.claim-section {
  display: flex;
  padding: 1.5rem;
  background-color: #eceff8;
  overflow-x: auto;
}
.claim-container {
  flex: 1 1 800px;
  min-width: 600px;
  max-width: 800px;
}
.portfolio-claim {
  flex: 1 1 30%;
  max-width: 240px;
}
.portfolio-claim > label {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.portfolio-claim + .portfolio-claim {
  margin-left: 10px;
}
.claim-edit-btn {
  width: 32px;
  height: 32px;
  margin-left: 15px;
  margin-top: 20px;
}
/*# sourceMappingURL=src/components/portfolio/details/PortfolioDetails.css.map */