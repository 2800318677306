@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'































































.claims-history
  &__title
    @extend $term-title
    text-transform uppercase

  &__author
    font-size 12px

  &__forc
    display inline-flex
    width 33%
    outline: 1px solid #ccc
    outline-offset: -6px
    padding: 8px 4px

  &__row
    margin-left: -2px
    margin-right: -8px

  &__forc-code
    flex-basis (4 / 7) * 100%
    text-align center

    &.include-peer-review
      flex-basis 50%

  &__apportion
    flex-basis (3 / 7) * 100%
    text-align center

    &.include-peer-review
      flex-basis 37.5%

  &__peer-review
    flex-basis 12.5%
    text-align center

  &__comment
    font-weight 400
