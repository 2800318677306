@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'





















































































































































































































































































































































































































.era-attributes .q-card
  margin-bottom 1rem

  .q-card-container
    padding .5rem

  .q-card-title
    line-height 1rem

.eligiblity-toggle >>> .q-option-label
  color $grey-7

