@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'















































































































































.apportion-row
  background-color $mq-sand
  padding-top 20.5px
  padding-bottom 1.5px

.peer-review-row
  background-color $mq-sand
  padding-top 5.5px
  padding-bottom 20.5px
  min-width 83px
  font-size 16px
.comment-row
  padding-top $radar-general-padding

.forc-selector
  min-width 140px
  font-size 16px

.apportion
  min-width 83px
  font-size 16px
