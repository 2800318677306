@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'
































































.collapsible-report
  min-width max-content

  /deep/ > .q-collapsible-inner > .q-item
    flex-wrap wrap
    position: sticky;
    top: 0;
    height 45px
    background-color: $grey-4;
    z-index: 2;

.gap
  order 2;
  flex-basis calc(100% + 28px);
  margin 5px -14px 0
  height 20px;
  background-color white;

.group-title
  font-size 18px

.report-buttons
  flex-grow 1
  order 1
  text-align right
  transition opacity .3s linear

  /.q-collapsible-closed &
    opacity 0

.active-button
  background-color $grey-5
  box-shadow inset 0 3px 5px rgba(0, 0, 0, 0.1);
