@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'





































































































































































































































.upload-container
  flex 1
  display flex
  flex-direction column
  text-align center

.q-field
  @extend $body-text

  >>> .q-field-label-inner
    justify-content flex-end
    text-align right

.xml-summary-table
  >>> th
    @extend $table-header-text
    background-color $mq-sand-dark

.btn-summary
   margin-top: 15px

.text-container
   padding:20px;
   text-align:left;
   font-size: 13px;
