.modal-search-wrapper {
  min-width: 741px;
  min-height: auto;
  padding: 16px 48px;
}
.q-layout-drawer {
  overflow: visible;
}
.drawer-toggle {
  margin-right: -14px;
  overflow: visible;
}
/*# sourceMappingURL=src/components/analytics/scenario/review/WhatIfScenarioReview.css.map */