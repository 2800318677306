@import '../../../../styles/variables/typography-vars.styl'
@import '../../../../styles/variables/mq-colors.styl'
@import '../../../../styles/variables/quasar-color-overrides.styl'
@import '../../../../styles/variables/quasar.variables.styl'
@import '../../../../styles/variables/app.variables.styl'

@import '../../../../styles/variables/extends.styl'



















































































































































































































































































.authors-wrapper
  min-height 2rem
  max-width 1px
  height 100%
  overflow hidden
  word-wrap break-word
  border 1px solid yellow

.bulk-error-wrapper
  width 517px

.bulk-error-wrapper-offset
  margin-right 37px

.validation-field
  margin-top 26px
