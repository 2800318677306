@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'
















































































































































$border-color = rgba(black, 40%);

.editor
  border 1px solid $border-color
  border-radius 6px
  overflow hidden

  &__content
    padding 20px 10px
    background-color rgba(white, 30%)

    >>> .ProseMirror:focus
      outline none

  &__header
    padding 5px;
    border-bottom 1px solid $border-color

    > * + *
      margin-left 5px

  &__footer
    padding 10px;
    border-top 1px solid $border-color

    > * + *
      margin-left 10px

.is-active
  background-color rgba(0,0,0,.1)

