.q-chip {
  border-radius: 50%;
  padding: 0 6px;
  min-height: 0;
  font-size: x-small;
}
.q-option {
  align-items: flex-start;
}
.flex-1 {
  flex: 1;
}
.gap-sm {
  gap: 10px;
}
/*# sourceMappingURL=src/components/research-output/details/ResearchOutputSensitivities.css.map */