@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'


























































































.bar
  height: 10px;
  border solid 1px #ffffff

.bar-container
  position relative
  display inline-block

.bar-labels
  width 100%
  overflow hidden
  font-size 12px
  font-width: normal

.class-info
  &.inverted
    .class-info-title
      color: $mq-white

  .class-info-title
    color: $mq-dark

