.body-2 {
  font-weight: 500;
}
.page-body-height {
  height: calc(100vh - 68px);
}
.page-body-height-minus-navigation {
  height: calc(100vh - 134px);
}
.search-bar {
  max-width: 220px;
}
.search-query {
  min-width: 200px;
}
/*# sourceMappingURL=src/components/research-output/search/ResearchOutputSearchAndList.css.map */