.no-portfolios {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
}
.p-name {
  flex-basis: 60%;
  max-width: 60%;
  width: 100%;
}
.p-claims {
  display: flex;
  flex-basis: 40%;
}
.p-claims span {
  flex-basis: 33%;
  text-align: center;
}
.p-header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 68px;
  background-color: #fff;
  border-bottom: 1px solid;
  z-index: 1;
}
.p-header .p-claims {
  justify-content: center;
}
.p-item {
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-height: 56px;
}
.p-item:nth-of-type(2n+1) {
  background-color: rgba(0,0,0,0.1);
}
.p-item a {
  display: flex;
  align-self: stretch;
  align-items: center;
  width: 100%;
}
.p-item a:link {
  text-decoration: none;
}
.p-item a:hover span {
  font-weight: 700;
  text-decoration: underline;
}
.p-item .p-name {
  color: #80225f;
}
.p-item .p-claims {
  font-size: 14px;
}
.layout-padding pre {
  font-family: 'Roboto', '-apple-system', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.portfolio-nav {
  height: 34px;
}
/*# sourceMappingURL=src/components/portfolio/PortfolioHome.css.map */