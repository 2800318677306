.apportion-row {
  background-color: #f4f2ef;
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}
.comment-row {
  padding-top: 16px;
}
.forc-selector {
  min-width: 140px;
  font-size: 16px;
}
.apportion {
  min-width: 83px;
  font-size: 16px;
}
.event-icon-wrapper {
  height: 58px;
  width: 58px;
  border: 1px solid;
  border-radius: 50%;
  background-color: #424b69;
}
.event-icon {
  font-size: 24px;
}
.add_claim,
.update_claim,
.remove_claim,
.claim_synced_from_previous_era,
.added_from_bulk {
  font-size: 28px;
  color: #fff;
}
/*# sourceMappingURL=src/components/research-output/claims-and-escalations/claim-events/ClaimUpdateEvent.css.map */