@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'









































.side-nav-section
  & + &
    margin-top: 15px;

.term-title
  padding 15px 0 10px 14px
  color $mq-blood-red
  min-height 0;
  font-weight 700
