.badge-container-large {
  display: table;
  line-height: 0;
  width: 6rem;
  height: 6rem;
  padding: 4px;
  font-size: 4.4rem;
}
.badge-container-small {
  display: table;
  background-color: #000;
  border-radius: 50%;
  line-height: 0;
  width: 40px;
  height: 40px;
  font-size: 30px;
}
.badge {
  border-radius: 50%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
}
.badge-border {
  border: 0.2rem solid #fff;
}
/*# sourceMappingURL=src/components/common/Badge.css.map */