@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'















.separator {
  text-align: center;
}

.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $primary;
}

.separator:not(:empty)::before {
  margin-right: 10px;
}

.separator:not(:empty)::after {
  margin-left: 10px;
}
