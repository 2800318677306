.xml-summary-table >>> th {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}
.xml-notification {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}
.search-bar {
  width: 15rem;
}
.pagination-total {
  padding: 0 15px;
  height: 48px;
  background-color: #fff;
  color: #c6007e;
}
.xml-notification {
  margin: 10px 6px 0;
  padding: 5px;
  width: 100%;
  background-color: #fff9c4;
  color: rgba(0,0,0,0.55);
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
}
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0;
}
.xml-summary-table >>> th {
  background-color: #ece8dc;
}
@-moz-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-o-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
/*# sourceMappingURL=src/components/xml/XmlOverview.css.map */