.forc-selector-min {
  width: 4rem;
}
.apportion-min {
  width: 4rem;
}
.bulk-claim-wrapper,
.bulk-error-wrapper {
  width: 607px;
}
.bulk-error-wrapper-offset {
  margin-right: 37px;
}
.authors-wrapper {
  min-height: 2rem;
  max-width: 1px;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  border: 1px solid #ff0;
}
.validation-field {
  margin-top: 26px;
}
.width-30 {
  width: 30px;
}
/*# sourceMappingURL=src/components/analytics/scenario/editor/WhatIfResearchOutputList.css.map */