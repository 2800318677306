@import '../../../../styles/variables/typography-vars.styl'
@import '../../../../styles/variables/mq-colors.styl'
@import '../../../../styles/variables/quasar-color-overrides.styl'
@import '../../../../styles/variables/quasar.variables.styl'
@import '../../../../styles/variables/app.variables.styl'

@import '../../../../styles/variables/extends.styl'











































































.apportion-item
  width 175px
