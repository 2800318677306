.status-container {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 14px;
}
.valid {
  background-color: #659d4e;
}
.under {
  background-color: #066ba0;
}
.over {
  background-color: #802243;
}
.claim_count_error {
  background-color: #c6007e;
}
.no_claims {
  background-color: #a79c78;
}
.portfolio_member {
  background-color: #606a8b;
}
.ineligible {
  background-color: #4e4e4e;
}
.clawback_invalid {
  background-color: #cd4646;
}
/*# sourceMappingURL=src/components/research-output/claims-and-escalations/ResearchOutputClaimStatus.css.map */