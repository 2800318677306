.option-item {
  padding: 15px;
}
.option-item .q-item-label {
  font-size: 16px;
}
.option-item .q-item-sublabel {
  font-size: 12px;
  padding-top: 2px;
}
.dialog-body {
  display: flex;
  align-items: flex-start;
}
/*# sourceMappingURL=src/components/research-output/claims-and-escalations/ResearchOutputEligibility.css.map */