.researcher-card__name {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
}
.researcher-card {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}
.researcher-card__status-label {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}
.researcher-card {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #ece8dc;
}
.researcher-card > div {
  flex-basis: 100%;
  width: 100%;
}
.researcher-card__details {
  display: flex;
  padding: 15px 15px 15px 0;
}
.researcher-card__ribbon {
  padding-right: 10px;
}
.researcher-card__layout {
  flex-grow: 1;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  grid-template-areas: "name" "statistics" "affiliations";
}
.researcher-card__name {
  grid-area: name;
  display: block;
  text-transform: capitalize;
  text-decoration: none;
}
.researcher-card__name:hover {
  text-decoration: underline;
}
.researcher-card__name:link,
.researcher-card__name:visited {
  color: #80225f;
}
.researcher-card__statistics {
  grid-area: statistics;
  display: flex;
  flex-wrap: wrap;
  margin: -5px -10px;
}
.researcher-card__ro-count {
  flex-grow: 1;
  min-width: 185px;
  padding: 10px;
}
.researcher-card__am-count {
  flex-basis: 120px;
  padding: 10px;
}
.researcher-card__affiliation {
  grid-area: affiliations;
  margin-bottom: 10px;
}
.researcher-card__affiliation dt:not(:first-of-type) {
  margin-top: 15px;
}
.researcher-card__status {
  padding: 15px;
  background-color: #eceff8;
}
.researcher-card__status > div {
  margin-bottom: 15px;
}
.researcher-card__status > div:last-of-type {
  flex: 1;
  margin-bottom: 0;
  padding: 0;
}
.researcher-card__status-label {
  margin-bottom: 5px;
  color: #424b69;
}
.researcher-card__ro-count {
  font-size: 16px;
}
a.researcher-card__ro-count {
  text-decoration: none;
}
a.researcher-card__ro-count:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .researcher-card .researcher-card__details {
    flex-basis: 60%;
  }
  .researcher-card .researcher-card__status {
    flex-basis: 40%;
  }
}
@media (min-width: 1024px) {
  .researcher-card .researcher-card__details {
    flex-basis: 70%;
  }
  .researcher-card .researcher-card__status {
    flex-basis: 30%;
  }
}
@media (min-width: 1400px) {
  .researcher-card__layout {
    grid-template-columns: 60% 40%;
    grid-template-areas: "name name" "affiliations statistics";
  }
  .researcher-card__statistics {
    margin: 0;
  }
  .researcher-card__ro-count,
  .researcher-card__am-count {
    padding: 0 15px 15px 0;
  }
}
/*# sourceMappingURL=src/components/researcher/researcher-search/ResearcherCard.css.map */