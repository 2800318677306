.container {
  height: 16px;
  width: 16px;
}
.container .cls-1 {
  fill: #fff;
}
.container .cls-2 {
  fill: #e52823;
}
.container .cls-3 {
  fill: #6d0020;
}
.container .cls-4 {
  fill: #b3b3b3;
}
/*# sourceMappingURL=src/components/common/MQBylineLogo.css.map */