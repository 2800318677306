@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'














































































































































































































.search-bar
  width 15rem

.pagination-total
  padding 0 15px
  height 48px
  background-color #fff
  color $primary
