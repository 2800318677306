.portfolio-select {
  height: 30px;
  font-size: 11px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
}
.header-top-section {
  height: 40px;
}
.col-toggle {
  width: 30px;
  text-align: right;
}
.col-action .wrappable-table-column {
  min-width: 70px;
  max-width: 115px;
  margin-top: -8px;
}
.col-pure-id {
  width: 100px;
}
.col-portfolio {
  min-width: 300px;
}
.col-claim {
  min-width: 150px;
  background-color: rgba(145,125,95,0.1);
}
.claim-container {
  display: flex;
  justify-content: center;
  max-width: 110px;
  margin: 0 auto;
  text-align: right;
}
.claim-container div:nth-of-type(2) {
  flex-grow: 1;
}
.claim-container div:last-of-type {
  min-width: 20px;
}
.ro-container {
  display: flex;
  min-width: 190px;
}
.ro-type {
  min-width: 20px;
  text-align: center;
  margin-right: 10px;
  line-height: 1.5;
}
.finalised {
  background-color: rgba(190,120,120,0.1) !important;
}
.valid {
  background-color: #659d4e;
}
.under {
  background-color: #066ba0;
}
.over {
  background-color: #802243;
}
.claim_count_error {
  background-color: #c6007e;
}
.no_claims {
  background-color: #a79c78;
}
.portfolio_member {
  background-color: #606a8b;
}
.ineligible {
  background-color: #4e4e4e;
}
.clawback_invalid {
  background-color: #cd4646;
}
.ro-title {
  text-align: justify;
}
a:link.javascript-link {
  color: #424242;
  text-decoration: none;
}
a:link.javascript-link:hover {
  text-decoration: underline;
}
a:visited {
  color: #2196f3;
}
a:visited:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=src/components/portfolio/search/PortfolioList.css.map */