.border
  border: 1px solid rgba(0, 0, 0, .1);

.border-double
  border-width: 2px !important;

$base-colors = (primary secondary negative tertiary)

for color in $base-colors
  .border-{color}
    border-color lookup('$' + color) !important
for color in $mqColors
  .border-{color}
    border-color lookup('$' + color) !important

for direction in left right top bottom
  .border-{direction}
    border-{direction}-color rgba(0, 0, 0, .1)
    border-{direction}-width 1px
    border-{direction}-style solid

  .border-{direction}-double
    border-{direction}-color rgba(0, 0, 0, .1)
    border-{direction}-style solid
    border-{direction}-width 2px

  for color in $base-colors
    .border-{direction}.border-{color}
      border-{direction}-color lookup('$' + color) !important
  for color in $mqColors
    .border-{direction}.border-{color}
      border-{direction}-color lookup('$' + color) !important
