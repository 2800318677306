@import 'variables/typography-vars.styl'
@import 'variables/mq-colors.styl'
@import 'variables/quasar-color-overrides.styl'
@import 'variables/quasar.variables.styl'
@import 'variables/app.variables.styl'

@import 'variables/extends.styl'
//@import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
@import './all-variables.styl'
@import '~quasar-styl'

.ro-claims
  font-size 16px
  font-weight 500
  font-style normal
  line-height 1.25
  letter-spacing 0.3px
  text-align left

a.q-link.q-tab.column.q-router-link-exact-active.q-router-link-exact-active
  div.q-tabs-bar
    display block !important

.bottom-aligned-field > div.row.col
  align-items center

.thick-slider
  .q-slider-track
    height 5px

.thin-slider
  .q-slider-track
    height 1px

.modal
  > .modal-backdrop
    background-color rgba(0, 0, 0, .8)

  > .modal-content
    max-height 90vh

.mq-byline
  position relative
  top 5px
  right 5px

@import './base/typography-legacy.styl'
@import './base/typography.styl'
@import './base/headings.styl'
@import './base/colors.styl'
@import './base/layout.styl'
@import './base/border.styl'
@import './base/links.styl'
@import './components/ribbon.styl'
@import './components/quasar-card-overrides.styl'
@import './components/quasar-form-overrides.styl'
@import './components/quasar-overrides.styl'
@import './components/collapsible.styl'
@import './components/mq-banner.styl'
@import './components/table.styl'
@import './components/claims-grid.styl'
@import './components/claim-editor.styl'
@import './components/data-quality.styl'
@import './components/xml-schema.styl'
@import './components/additional-info.styl'

