@import '../../../../styles/variables/typography-vars.styl'
@import '../../../../styles/variables/mq-colors.styl'
@import '../../../../styles/variables/quasar-color-overrides.styl'
@import '../../../../styles/variables/quasar.variables.styl'
@import '../../../../styles/variables/app.variables.styl'

@import '../../../../styles/variables/extends.styl'





















































































































































































.page-scroll-height
  height calc(100vh - 96px)
  overflow auto

.forc-performance-comparison.selected
  border 1px solid $mq-magenta !important

.forc-performance-comparison:not(:first-child)
  border-top 0
