@import '../styles/variables/typography-vars.styl'
@import '../styles/variables/mq-colors.styl'
@import '../styles/variables/quasar-color-overrides.styl'
@import '../styles/variables/quasar.variables.styl'
@import '../styles/variables/app.variables.styl'

@import '../styles/variables/extends.styl'





























.app-header
  background-color $mq-sand !important
  z-index 1000 !important

.app-footer
  background-color white
  border-top 1px solid #ccc
  box-shadow none
  z-index: 1000;
