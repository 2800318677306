.option-item {
  padding: 15px;
}
.option-item .q-item-label {
  font-size: 16px;
}
.option-item .q-item-sublabel {
  font-size: 12px;
  padding-top: 2px;
}
/*# sourceMappingURL=src/components/researcher/researcher-details/ResearcherEligibility.css.map */