$claim-cell-item-height = 36px
$claim-cell-enrich-item-height = 20px

.claim-cell-item
  height $claim-cell-item-height

.forc-apportion-cell-item
  width 146px

.claim-cell-enrich-item
  height $claim-cell-enrich-item-height

.claim-cell-item-and-enrich-placeholder
  height $claim-cell-item-height + $claim-cell-enrich-item-height
