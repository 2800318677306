.ro-title {
  text-align: justify;
}
a:link.javascript-link {
  color: #424242;
  text-decoration: none;
}
a:link.javascript-link:hover {
  text-decoration: underline;
}
a:visited {
  color: #2196f3;
}
a:visited:hover {
  text-decoration: underline;
}
.affiliations {
  margin-left: 2.2rem;
}
span.author-name {
  text-decoration: none;
}
a.author-name {
  text-decoration: none;
}
a.author-name:hover {
  text-decoration: underline;
}
.author-name {
  font-size: 10px;
  overflow-wrap: break-word;
}
.term {
  font-size: 10px;
  font-weight: 500;
}
.term-items {
  font-size: 10px;
}
.authors {
  margin-top: 12px;
}
.select-ro-button {
  font-size: 28px !important;
  font-weight: 500;
}
/*# sourceMappingURL=src/components/research-output/search/ResearchOutputMinimal.css.map */