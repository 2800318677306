.claims-history__title {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.9px;
  color: #424b69;
}
.claims-history__title {
  text-transform: uppercase;
}
.claims-history__author {
  font-size: 12px;
}
.claims-history__forc {
  display: inline-flex;
  width: 33%;
  outline: 1px solid #ccc;
  outline-offset: -6px;
  padding: 8px 4px;
}
.claims-history__row {
  margin-left: -2px;
  margin-right: -8px;
}
.claims-history__forc-code {
  flex-basis: 57.14285714285714%;
  text-align: center;
}
.claims-history__forc-code.include-peer-review {
  flex-basis: 50%;
}
.claims-history__apportion {
  flex-basis: 42.857142857142854%;
  text-align: center;
}
.claims-history__apportion.include-peer-review {
  flex-basis: 37.5%;
}
.claims-history__peer-review {
  flex-basis: 12.5%;
  text-align: center;
}
.claims-history__comment {
  font-weight: 400;
}
/*# sourceMappingURL=src/components/claim/ClaimsHistory.css.map */