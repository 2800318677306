@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'

































































































































































































.roles
  margin-top 0
  margin-bottom 0
  padding-inline-start 0

  li:not(:first-child)
    border-top 1px solid $mq-separator
    margin-top 2px
    padding-top 2px

.user-header-row
  border-bottom: 2px solid rgba(0,0,0,.25)
  border-top: 1px solid rgba(0,0,0,.25);
  color: #222;
  text-transform: uppercase;
