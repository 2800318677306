@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'

















































































































































































































































.claim-section
  display flex
  padding 1.5rem
  background-color $mq-neutral
  overflow-x auto

.claim-container
  flex 1 1 800px
  min-width 600px
  max-width 800px

.portfolio-claim
  flex 1 1 30%
  max-width 240px

  > label
    @extend $term-title
    display block
    margin-bottom 5px
    text-transform uppercase

  & + &
    margin-left 10px

.claim-edit-btn
  width 32px
  height 32px
  margin-left 15px
  margin-top 20px
