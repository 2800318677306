@import '../../../../styles/variables/typography-vars.styl'
@import '../../../../styles/variables/mq-colors.styl'
@import '../../../../styles/variables/quasar-color-overrides.styl'
@import '../../../../styles/variables/quasar.variables.styl'
@import '../../../../styles/variables/app.variables.styl'

@import '../../../../styles/variables/extends.styl'














































.apportion-row
  background-color $mq-sand
  padding-top 20.5px
  padding-bottom 20.5px

.event-icon-wrapper
  height 58px
  width 58px
  border 1px solid
  border-radius 50%
  background-color $mq-soft

.event-icon
  font-size 24px
  color white
