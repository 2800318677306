@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'
















































.badge-container-large
  display: table
  line-height 0
  width 6rem
  height 6rem
  padding 4px
  font-size 4.4rem

.badge-container-small
  display: table
  background-color: black
  border-radius: 50%
  line-height 0
  width 40px
  height 40px
  font-size 30px

.badge
  border-radius: 50%
  display table-cell
  vertical-align middle
  text-align center
  text-decoration none

.badge-border
  border: .2rem solid white;

