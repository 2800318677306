@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'





































$outer-color = $mq-soft
$inner-color = $mq-magenta

.outer-circle, .mid-circle, .inner-circle
  fill currentColor

.outer-circle, .inner-circle
  stroke-width 8px

.mid-circle
  stroke #80225f
  stroke-width 20px

.outer-circle
  stroke $outer-color

.outer-arrow
  fill $outer-color

.inner-circle
  stroke $inner-color

.inner-arrow
  fill $inner-color

@keyframes rotating
  from
    transform rotate(0deg)
  to
    transform rotate(360deg)

.spin
  animation rotating 7.5s linear infinite

.reverse-spin
  animation rotating 6s linear infinite
  animation-direction reverse

.spin, .reverse-spin
  transform-origin 50%
  animation-play-state paused

.active
  .spin, .reverse-spin
    animation-play-state running
