.roles {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 0;
}
.roles li:not(:first-child) {
  border-top: 1px solid #b5c3d5;
  margin-top: 2px;
  padding-top: 2px;
}
.user-header-row {
  border-bottom: 2px solid rgba(0,0,0,0.25);
  border-top: 1px solid rgba(0,0,0,0.25);
  color: #222;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/components/user/UserList.css.map */