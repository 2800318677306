.simple-title
  font-size 16px
  font-weight 500
  font-style normal
  line-height 1.25
  letter-spacing normal

.r-heading-1-dense
  font-size 24px
  font-weight normal
  font-style normal
  line-height 1.33
  text-align left

.r-heading-1
  @extend .r-heading-1-dense
  padding $radar-general-padding $radar-medium-padding

.r-heading-2
  font-size 14px
  font-weight normal
  font-style normal
  font-stretch normal
  line-height 1.43
  letter-spacing 0.2px

.r-heading-3
  font-size 20px
  font-weight normal
  font-style normal
  line-height 1.4
  letter-spacing 0px

.page-title
  @extend .r-heading-1
  color $mq-radar-dark

.page-title-large
  @extend @page-title
  font-size 30px

.page-title-dense
  @extend .r-heading-1-dense
  color $mq-soft

.headline-title
  @extend .r-heading-3
  color $mq-soft

.radar-sublabel
  font-size 12px
  font-weight 500
  font-style normal
  line-height 1.33
  letter-spacing 1.9px
  color $mq-soft

.tall-header
  height $app-header-height

.header-margin
  margin-top: $app-header-height

.header-padding
  padding-top: $app-header-height
