.headline-title {
  position: sticky;
  top: 0;
  padding-left: 20px;
  padding-bottom: 1rem;
  background-color: #f4f2ef;
  z-index: 1;
}
.position-title {
  position: sticky;
  top: 44px;
  margin-left: -20px;
  padding-left: 20px;
  padding-bottom: 15px;
  background-color: #e5e1da;
  border-top: 2px solid rgba(0,0,0,0.2);
  overflow-y: auto;
  overflow-x: hidden;
}
.is-primary {
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 3px;
}
dl {
  margin-bottom: 0.5rem;
}
dd {
  margin-bottom: 0.5rem;
}
dt {
  text-transform: uppercase;
  margin-top: 1rem;
}
.q-toggle {
  margin-bottom: 5px;
}
.q-toggle >>> .q-option-label {
  opacity: 0;
  transition: none;
}
.q-toggle >>> .active + .q-option-label {
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #2e7d32;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  cursor: default;
}
/*# sourceMappingURL=src/components/researcher/researcher-details/ResearcherAffiliations.css.map */