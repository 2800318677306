@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'














































































$stats-item
  padding 6px
  color black
  text-decoration none

  & > div
    padding 6px 0
    border-radius 4px

    &:not(.no-link):hover
      background-color $indigo-2
      font-weight 700

    &.no-link:hover
      cursor not-allowed

    div:last-child
      padding-top 5px
      color $mq-magenta

.stats
  &__container
    display flex
    flex-wrap wrap
    margin-top 10px
    text-transform uppercase
    font-size 12px
    text-align center

  &__total
    flex-basis 50px
    @extend $stats-item

  &__count
    flex 1
    flex-basis 80%
    display flex
    flex-wrap wrap
    background-color $mq-neutral

  &__item
    flex-basis 20%
    max-width 50px
    @extend $stats-item

    &:nth-child(5)
      flex-basis calc(max(100% - 200px, 20%))
      max-width none;

