$claim-font-size = 13px
$claim-border-width = 1px
$claim-cell-height = 32px

$claim-container
  display grid
  grid-template-rows $claim-cell-height
  border-color $mq-sand
  text-align center
  align-items center
  font-size $claim-font-size

  > *
    display inline-grid
    align-items center
    height $claim-cell-height

  .q-if
    font-size $claim-font-size

$claim-borders
  border-color rgba(0,0,0,.1)
  .claim-editor__forc
    border-color rgba(0,0,0,.1)

.claim-editor
  &__view
    @extend $claim-container
    grid-template-columns 4fr 3fr
    background-color #fff
    border 1px solid transparent
    border-radius 6px

    &.empty
      @extend $claim-borders
      background-color $mq-sand-dark
      color $mq-sand-dark

    &.read-only
      @extend $claim-borders
      background-color $mq-soft-muted-light

    .claim-editor__peer-review
      .cursor-pointer
        cursor default !important

      .text-faded
        color: #ccc !important

  &__entry
    @extend $claim-container
    grid-template-columns 2fr 1fr 35px

    .claim-editor__forc, .claim-editor__apportion
      background-color #fff

    .forc-selector.q-select
        color $primary !important

    .forc-selector
      .q-if-inner,
      .q-icon
        color #000 !important

  &__group
    grid-area: 1 / 1 / 2 / 3
    border-radius 6px

  &__forc
    grid-area: 1 / 1 / 2 / 2
    border-right $claim-border-width solid $mq-sand

  &__apportion
    grid-area: 1 / 2 / 2 / 3

  &__action
    grid-area: 1 / 3 / 2 / 4
    margin-left 5px

  &__btn
    max-width 30px
    min-height 30px

  &__hidden-forc
    position absolute
    opacity 0
    z-index -1

  &--has-peer-review
    position relative
    padding-right 27px

  &__peer-review
    position absolute
    padding 5px 3px
    right 0

    /.claim-editor__entry &
      border-bottom 1px solid #bbb

      &:hover
        border-bottom 1px solid black

    &--underline
      position absolute
      bottom -1px
      left 0
      right 0
      height 2px
      border-bottom 2px solid $mq-magenta
      transform scaleX(0)
      transition transform 200ms ease-in-out

      /.q-checkbox:focus + &
        transform: scaleX(1)

.claim-btn
  max-width 30px
  min-height 30px
