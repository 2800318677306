.search-fields >>> .q-field .q-field-label,
.search-fields >>> .q-field .q-field-error {
  color: #fff !important;
  font-size: 12px !important;
}
.search-fields >>> .q-field:first-of-type .q-field-error {
  white-space: nowrap;
}
.search-fields >>> .q-field .q-field-required {
  color: #008000 !important;
}
.q-field-required span::before {
  content: '*';
  color: #f00;
}
.q-btn-group {
  padding-top: 0px;
}
.search-fields >>> .q-field span {
  font-size: 12px !important;
}
/*# sourceMappingURL=src/components/bulk-code/BulkCodeSearch.css.map */