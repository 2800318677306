.page-scroll-height {
  height: calc(100vh - 96px);
  overflow: auto;
}
.forc-performance-comparison.selected {
  border: 1px solid #c6007e !important;
}
.forc-performance-comparison:not(:first-child) {
  border-top: 0;
}
/*# sourceMappingURL=src/components/analytics/scenario/ForcPerformanceComparisons/WhatIfForcPerformanceComparisons.css.map */