@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'

























































































































































.q-chip {
  border-radius: 50%;
  padding: 0 6px;
  min-height: 0;
  font-size: x-small;
}

.q-option {
  align-items: flex-start;
}

.flex-1 {
  flex: 1;
}

.gap-sm {
  gap: 10px;
}
