.q-field {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}
.xml-summary-table >>> th {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}
.upload-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.q-field >>> .q-field-label-inner {
  justify-content: flex-end;
  text-align: right;
}
.xml-summary-table >>> th {
  background-color: #ece8dc;
}
.btn-summary {
  margin-top: 15px;
}
.text-container {
  padding: 20px;
  text-align: left;
  font-size: 13px;
}
/*# sourceMappingURL=src/components/research-income/ResearchIncomeUpload.css.map */