.separator {
  text-align: center;
}
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #c6007e;
}
.separator:not(:empty)::before {
  margin-right: 10px;
}
.separator:not(:empty)::after {
  margin-left: 10px;
}
/*# sourceMappingURL=src/components/common/SeparatorWithText.css.map */