@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'












































































































































































































































































































































































































































































































































































































































































































































.forc-selector-min
  width: 4rem

.apportion-min
  width: 4rem

.bulk-claim-wrapper, .bulk-error-wrapper
  width 637px

.bulk-error-wrapper
  & > div
    margin-right 37px

.bulk-claim-wrapper
  min-height: 57px;

.authors-wrapper
  min-height 2rem
  max-width 1px
  height 100%
  overflow hidden
  word-wrap break-word
  border 1px solid yellow

.term-title-condensed
  min-width: 102px !important

.bulk-code-table
  >>> table
    table-layout: fixed

  >>> .q-table-top:empty
    display none

  &.fullscreen
    >>> .q-table-top
      position fixed
      top 0
      left 0
      right 0
      padding 0
      background #fff
      z-index 910

    >>> .q-table-middle
      padding-top 147px

      th
        top 147px
