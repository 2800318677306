@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'


















































































































.menu-icon, .menu-text
  color $mq-black

.q-item.router-link-exact-active
  background-color $mq-highlight
  color white

  .menu-icon, .menu-text
    color white

