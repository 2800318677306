@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'






























































































































.researcher-card
  @extend $body-text
  display flex
  flex-wrap wrap
  background-color white
  border 1px solid $mq-sand-dark

  > div
    flex-basis 100%
    width 100%

  &__details
    display flex
    padding 15px 15px 15px 0

  &__ribbon
    padding-right 10px

  &__layout
    flex-grow 1
    display grid
    gap: 15px;
    grid-template-columns 1fr
    grid-template-areas "name" "statistics" "affiliations"

  &__name
    grid-area name
    @extend $h3
    display block
    text-transform capitalize
    text-decoration none

    &:hover
      text-decoration underline

    &:link,
    &:visited
      color $mq-purple

  &__statistics
    grid-area statistics
    display flex
    flex-wrap wrap
    margin -5px -10px

  &__ro-count
    flex-grow 1
    min-width 185px
    padding 10px

  &__am-count
    flex-basis 120px
    padding 10px

  &__affiliation
    grid-area affiliations
    margin-bottom 10px

    dt:not(:first-of-type)
      margin-top 15px

  &__status
    padding 15px
    background-color $mq-neutral

    > div
      margin-bottom 15px

      &:last-of-type
        flex: 1
        margin-bottom 0
        padding 0

  &__status-label
    @extend $label-text
    margin-bottom 5px
    color $mq-soft

  &__ro-count
    font-size $font-size-5

    a&
      text-decoration none
      &:hover
        text-decoration underline

  @media (min-width 768px)
    & &__details
      flex-basis 60%

    & &__status
      flex-basis 40%

  @media (min-width 1024px)
    & &__details
      flex-basis 70%

    & &__status
      flex-basis 30%

  @media (min-width 1400px)
    &__layout
      grid-template-columns 60% 40%
      grid-template-areas "name name" "affiliations statistics"

    &__statistics
      margin 0

    &__ro-count,
    &__am-count
      padding 0 15px 15px 0

