@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'


















































































































































.forc-filter
  width 20%

tr
  :not(:last-child)
    border-right-color rgba(0, 0, 0, .1)
    border-right-width 1px
    border-right-style solid

