@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'































































































































































.ro-title
  text-align justify

a:link
  &.javascript-link
    color: $dark
    text-decoration none

    &:hover
      text-decoration underline

a:visited
  color $blue

  &:hover
    text-decoration underline

.affiliations
  margin-left 2.2rem

span.author-name
  text-decoration none

a.author-name
  text-decoration none

  &:hover
    text-decoration underline

.author-name
  font-size 10px
  overflow-wrap break-word

.term
  font-size 10px
  font-weight 500

.term-items
  font-size 10px

.authors
  margin-top 12px

.select-ro-button
  font-size 28px !important
  font-weight 500

