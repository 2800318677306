.researcher-details {
  flex-grow: 1;
  position: relative;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  grid-template-areas: "affiliations forc-stats";
  gap: 20px;
  padding: 20px;
  padding-left: 0;
}
@media (min-width: 992px) {
  .researcher-details {
    grid-template-columns: 1fr 3fr;
  }
}
.affiliations-section {
  grid-area: affiliations;
  overflow-y: auto;
  overflow-x: hidden;
}
.forc-stats-section {
  grid-area: forc-stats;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.forc-stats-section > div {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
}
.forc-stats-section > div + div {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .forc-stats-section .forcs-section {
    flex-basis: calc(66% - 10px);
    max-width: calc(66% - 10px);
  }
  .forc-stats-section .statistics-section {
    margin-top: 0;
    flex-basis: calc(33% - 10px);
    max-width: calc(33% - 10px);
  }
}
dl {
  margin-bottom: 0.5rem;
}
dd {
  margin-bottom: 0.5rem;
}
dt {
  text-transform: uppercase;
}
.statistics-panel {
  padding: 20px 10px;
  background-color: #fff;
}
.statistics-panel + .statistics-panel {
  border-top: 4px solid #f4f2ef;
}
/*# sourceMappingURL=src/components/researcher/researcher-details/ResearcherDetails.css.map */