@import '../../../../styles/variables/typography-vars.styl'
@import '../../../../styles/variables/mq-colors.styl'
@import '../../../../styles/variables/quasar-color-overrides.styl'
@import '../../../../styles/variables/quasar.variables.styl'
@import '../../../../styles/variables/app.variables.styl'

@import '../../../../styles/variables/extends.styl'



























































































































.forc-selector-min
  width: 4rem

.apportion-min
  width: 4rem

.bulk-claim-wrapper
  width 866px

.authors-wrapper
  min-height 2rem
  max-width 1px
  height 100%
  overflow hidden
  word-wrap break-word
  border 1px solid yellow

.what-if-claim-viewer
  width: 181.5px;
  height: 36px;
  border: solid 1px #6b7c93;
  background-color: rgb(107, 124, 147, 0.1);

