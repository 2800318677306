@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'





























































.research-output-slider-open
  font-size 11.7px

.highlighted-left-border
  border-left solid 1px $secondary

.active-border
  border-bottom solid 1px $secondary

