.citation-table {
  flex: 1;
  display: flex;
}
.citation-table >>> table {
  table-layout: fixed;
}
.citation-table >>> table td:first-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
}
.citation-table >>> th {
  position: sticky;
  top: 0;
  padding-bottom: 10px !important;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  background-color: #fff;
  z-index: 901;
}
/*# sourceMappingURL=src/components/analytics/overview/CitationsOverviewTable.css.map */