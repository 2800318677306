.mq-banner
  background -webkit-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background -o-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background -ms-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background -moz-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background linear-gradient(to right, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)

.mq-banner-secondary
  background #80225f;
  background -moz-linear-gradient(left, #80225f 0%, #a41443 51%, #76232f 100%)
  background -webkit-linear-gradient(left, #80225f 0%, #a41443 51%, #76232f 100%)
  background linear-gradient(to right, #80225f 0%, #a41443 51%, #76232f 100%)
  filter-gradient(#80225f, #76232f, horizontal)

.mq-banner-overlay
  content url('../assets/mq-brand-texture.png')
  position absolute
  top 0
  right 0
  max-width 100%
  max-height auto

