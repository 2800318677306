.outer-circle,
.mid-circle,
.inner-circle {
  fill: currentColor;
}
.outer-circle,
.inner-circle {
  stroke-width: 8px;
}
.mid-circle {
  stroke: #80225f;
  stroke-width: 20px;
}
.outer-circle {
  stroke: #424b69;
}
.outer-arrow {
  fill: #424b69;
}
.inner-circle {
  stroke: #c6007e;
}
.inner-arrow {
  fill: #c6007e;
}
.spin {
  animation: rotating 7.5s linear infinite;
}
.reverse-spin {
  animation: rotating 6s linear infinite;
  animation-direction: reverse;
}
.spin,
.reverse-spin {
  transform-origin: 50%;
  animation-play-state: paused;
}
.active .spin,
.active .reverse-spin {
  animation-play-state: running;
}
@-moz-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=src/components/common/RadarLogo.css.map */