@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'






















































































































































































































































































.search-fields
  >>> .q-field
    .q-field-label,
    .q-field-error
      color #fff !important
      font-size 12px !important

    &:first-of-type
      .q-field-error
        white-space nowrap

     .q-field-required
       color: green!important

.q-field-required span
  &::before
    content: '*'
    color: red

 .q-btn-group
    padding-top: 0px;

.search-fields
  >>> .q-field span
      font-size 12px !important
