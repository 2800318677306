@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'















































































































































































































































































































































































































































































.search-bar
  width 250px

.grid-cell
  padding 5px 0
  vertical-align top

$grid-container
  position relative
  display grid

  > div
    padding 0 5px

.detail-section
  @extend $grid-container
  grid-template-columns 25px 75px 120px 2fr 2fr 3fr
  grid-template-rows 1fr auto
  grid-template-areas \
    "toggle pureid  leadci schemebody herdc title" \
    "toggle info    info   info       info  title"

.col-toggle
  grid-area toggle
  position absolute
  margin-top -2px
  padding-left 0

.col-title
  grid-area title
  min-width 180px
  white-space normal
  text-align left

.col-pureid
  grid-area pureid
  text-align left

.col-leadci
  grid-area leadci
  white-space normal
  text-align left

.col-schemebody
  grid-area schemebody
  min-width 100px
  white-space normal
  text-align left

.col-herdc
  grid-area herdc
  white-space normal
  text-align left

.col-info
  grid-area info

.fund-section
  min-width 160px

  > *
    padding 0 5px

.col-income-type
  float left
  text-transform uppercase

  /tbody &
    font-size 10px

.col-funding
  float right
  text-align right

td
  .col-income-type
    margin 0 10px 5px 0

  .col-funding
    min-width 190px

  .col-year
    margin-right 10px
    width 50px

.claim-section
  @extend $grid-container
  min-width 450px
  grid-template-columns 1fr 1fr 1fr 40px
  grid-template-rows 1fr auto
  grid-template-areas \
    "claim1     claim2     claim3     status" \
    "claim-info claim-info claim-info status"

  [class^=col-claim]
    min-width 120px
    text-align center

.col-claim1
  grid-area claim1

.col-claim2
  grid-area claim2

.col-claim3
  grid-area claim3

.col-status
  grid-area status
  padding-top 2px

.col-claim-info
  grid-area claim-info
