@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'




































































































































































































































$breakpoint = 992px

.researcher-details
  flex-grow 1
  position relative
  display grid
  grid-template-columns 2fr 3fr
  grid-template-rows auto
  grid-template-areas "affiliations forc-stats"
  gap 20px
  padding 20px
  padding-left 0

  @media (min-width $breakpoint)
    grid-template-columns 1fr 3fr

.affiliations-section
  grid-area affiliations
  overflow-y auto
  overflow-x hidden

.forc-stats-section
  grid-area forc-stats
  overflow-y auto
  display flex
  flex-wrap wrap
  justify-content space-between
  width 100%

  > div
    flex-basis 100%
    max-width 100%
    width 100%

  > div + div
    margin-top 20px

  @media (min-width $breakpoint)
    .forcs-section
      flex-basis calc(66% - 10px)
      max-width calc(66% - 10px)

    .statistics-section
      margin-top 0
      flex-basis calc(33% - 10px)
      max-width calc(33% - 10px)

dl
  margin-bottom .5rem

dd
  margin-bottom .5rem

dt
  text-transform uppercase

.statistics-panel
  padding: 20px 10px;
  background-color #fff

  & + &
    border-top 4px solid $mq-sand

