body
  background-color $mq-sand

.full-page
  display flex
  flex-direction column
  height 100vh

.scrollable-section
  display flex
  flex 1
  overflow-y auto

.sticky-position
  position sticky

.overflow-visible
  overflow visible

.full-page-content-height
  min-height: calc(100vh - 50px)
  max-height: calc(100vh - 50px)

.full-page-content-height-with-tabs
  min-height: calc(100vh - 109px)
  max-height: calc(100vh - 109px)

.stretch-out
  margin-left: 0 - $radar-general-padding

hr.stretch-out
  margin: 0

.layout-height
  min-height: 100vh
