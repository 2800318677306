@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'



















































































.container
    height 16px
    width 16px

    .cls-1
        fill #fff

    .cls-2
        fill #e52823

    .cls-3
        fill #6d0020

    .cls-4
        fill #b3b3b3

