@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'





























































































































































































.apportion-row
  background-color $mq-sand
  padding-top 20.5px
  padding-bottom 1.5px

.peer-review-row
  background-color $mq-sand
  padding-top 5.5px
  padding-bottom 20.5px
  min-width 83px
  font-size 16px

.forc-selector
  min-width 140px
  font-size 16px

.apportion
  min-width 83px
  font-size 16px

.user-and-time
  font-size 14px
  font-weight normal
  font-style normal
  line-height 1.36
  letter-spacing 0.2px
  color rgba(0, 0, 0, 0.6)

.border-green-9
  border-color $green-9
