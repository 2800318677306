@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'

















































































































































































































.claim-row
  margin-top 15px
  padding 10px
  background-color $tertiary
  border 3px solid rgba(0, 0, 0, .1)
  border-radius 6px
