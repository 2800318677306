@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'




































































































































































































































































































































































































































































.search-bar
  flex-basis 250px

.grid-cell
  padding 5px 0
  vertical-align top

.ineligible
  background-color #eee
  color #888

  .claim-btn
    display none

.ineligible-tooltip
  background-color $mq-blood-red
  color #fff
  font-size $font-size-3
  font-weight 500

.applied-measures-table
  >>> th.grid-cell
    vertical-align top
    padding-top 20px

$grid-container
  position relative
  display grid

  > div
    padding 0 5px

.detail-section
  @extend $grid-container
  grid-template-columns 25px 1fr 100px 50px 135px
  grid-template-areas \
    "toggle title    am-type  year     autoforc" \
    "toggle authors  authors  year     autoforc" \
    "toggle children children children children"

.col-toggle
  grid-area toggle
  position absolute
  margin-top -2px
  padding-left 0

.col-title
  grid-area title
  min-width 180px
  white-space normal

.col-authors
  grid-area authors

.col-am-type
  grid-area am-type

.col-year
  grid-area year

.col-autoforc
  grid-area autoforc
  text-align right

  span
    display inline-block
    width 50px

.col-info
  grid-area info

.col-children
  grid-area children

.claim-section
  @extend $grid-container
  min-width 470px
  grid-template-columns 1fr 1fr 1fr 40px
  grid-template-rows 1fr auto
  grid-template-areas \
    "claim1     claim2     claim3     status" \
    "claim-info claim-info claim-info status"

  [class^=col-claim]
    min-width 140px
    text-align center

.col-claim1
  grid-area claim1

.col-claim2
  grid-area claim2

.col-claim3
  grid-area claim3

.col-status
  grid-area status
  padding-top 2px

.col-claim-info
  grid-area claim-info
