.ro-stats__total,
.ro-stats__item {
  color: #000;
  text-decoration: none;
}
.ro-stats__total > div,
.ro-stats__item > div {
  padding: 6px 0;
  border-radius: 4px;
}
.ro-stats__total > div:not(.no-link):hover,
.ro-stats__item > div:not(.no-link):hover {
  background-color: #c5cae9;
  font-weight: 700;
}
.ro-stats__total > div.no-link:hover,
.ro-stats__item > div.no-link:hover {
  cursor: not-allowed;
}
.ro-stats__total > div div:last-child,
.ro-stats__item > div div:last-child {
  padding-top: 5px;
  color: #c6007e;
}
.ro-stats__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
}
.ro-stats__total {
  flex-basis: 50px;
  padding: 4px;
}
.ro-stats__count {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  background-color: #eceff8;
}
.ro-stats__item {
  flex-basis: 50px;
  max-width: 50px;
}
/*# sourceMappingURL=src/components/researcher/researcher-search/ResearcherPublications.css.map */