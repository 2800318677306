@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'




















































































.additional-info
  display grid
  grid-template-columns 100px 1fr
  padding 5px
  border 1px solid rgba(0, 0, 0, .2)
  border-radius 6px

.child-eligibility
  align-items center

  >>> .q-toggle-handle
    align-items center
    background-color #e5e5e5

  >>> .q-option-label
    display block
    margin 5px 0 0 0
    padding 2px 6px
    border-radius 4px
    background-color $grey-6
    color #fff
    font-size 10px
    text-transform uppercase
    opacity 1
    transition background-color .5s ease-out

  >>> .active + .q-option-label
    background-color $green-9

  .q-toggle
    display block
    margin-left 20px

    &[tabindex="-1"]
      cursor default !important

      >>> .q-option-inner
        display none

    &.is-changed
      >>> .q-option-label
        background-color $orange-9
.info-label
  min-width 60px
