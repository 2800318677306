.bar {
  height: 10px;
  border: solid 1px #fff;
}
.bar-container {
  position: relative;
  display: inline-block;
}
.bar-labels {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-width: normal;
}
.class-info.inverted .class-info-title {
  color: #fff;
}
.class-info .class-info-title {
  color: #707070;
}
/*# sourceMappingURL=src/components/analytics/charts/SimpleCitationsOverviewChart.css.map */