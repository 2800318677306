a
  color var(--q-color-secondary)

.link-button
  text-decoration underline
  background-color transparent
  border-color transparent
  font-weight 400
  background-color transparent
  cursor pointer

  &:focus
    border: none
    border-color: transparent

a.link-image
  text-decoration none
  color none
  border-color transparent
  font-weight 100
  background-color transparent
  cursor pointer

  &:focus
    border: none
    border-color: transparent
