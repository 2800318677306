@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'




























































































































.option-item
  padding 15px

  .q-item-label
    font-size 16px

  .q-item-sublabel
    font-size 12px
    padding-top 2px

.dialog-body
  display flex
  align-items flex-start
