@import '../../../../styles/variables/typography-vars.styl'
@import '../../../../styles/variables/mq-colors.styl'
@import '../../../../styles/variables/quasar-color-overrides.styl'
@import '../../../../styles/variables/quasar.variables.styl'
@import '../../../../styles/variables/app.variables.styl'

@import '../../../../styles/variables/extends.styl'

























































































































.modal-search-wrapper
  min-width 741px
  min-height auto
  padding $radar-general-padding $radar-large-padding

.q-layout-drawer
  overflow visible

.drawer-toggle
  margin-left -12px
  overflow visible

