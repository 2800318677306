.apportion-row {
  background-color: #f4f2ef;
  padding-top: 20.5px;
  padding-bottom: 1.5px;
}
.peer-review-row {
  background-color: #f4f2ef;
  padding-top: 5.5px;
  padding-bottom: 20.5px;
  min-width: 83px;
  font-size: 16px;
}
.comment-row {
  padding-top: 16px;
}
.forc-selector {
  min-width: 140px;
  font-size: 16px;
}
.apportion {
  min-width: 83px;
  font-size: 16px;
}
/*# sourceMappingURL=src/components/research-output/claims-and-escalations/NewResearchOutputClaim.css.map */