a.icon-link {
  color: #80225f;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
}
a.icon-link {
  text-decoration: none;
}
a.icon-link:visited {
  color: #80225f;
  text-decoration: none;
}
.affiliations {
  margin-left: 2.2rem;
}
.author-name {
  text-decoration: none;
  line-height: 1.1rem;
}
.abstract {
  text-decoration: underline;
}
.q-collapsible .term-definition {
  margin-bottom: 24px;
}
.claim-status {
  font-size: 20px;
  line-height: 1.3;
  color: #76232f;
  text-transform: capitalize;
}
.dispute-status {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  color: #424b69;
  text-transform: capitalize;
}
.forc {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.3px;
  color: #6b7c93;
}
.apportion {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #000;
  word-break: keep-all;
}
.icon-highlight-alert > .q-alert > .q-alert-side > .q-icon {
  color: #db2828;
}
.sensitivity::v-deep .q-dot {
  background: #80235f;
}
/*# sourceMappingURL=src/components/research-output/details/ResearchOutputWorkArea.css.map */