for direction in left right top bottom
  .r-p-{direction}
    padding-{direction} $radar-general-padding

  .r-p-{direction}-none
    padding-{direction} 0 !important

  .r-p-large-{direction}
    padding-{direction} $radar-large-padding

  .r-p-medium-{direction}
    padding-{direction} $radar-medium-padding

  .r-m-{direction}
    margin-{direction} $radar-general-padding

  .r-m-medium-{direction}
    margin-{direction} $radar-medium-padding

  .r-m-large-{direction}
    margin-{direction} $radar-large-padding

  .r-m-{direction}-none
    margin-{direction} 0 !important

.r-p-side
  padding-left $radar-general-padding
  padding-right $radar-general-padding

.r-p-medium-side
  padding-left $radar-medium-padding
  padding-right $radar-medium-padding

.r-p-medium-vertical
  padding-top $radar-medium-padding
  padding-bottom $radar-medium-padding

.r-p-large-side
  padding-left $radar-large-padding
  padding-right $radar-large-padding

.r-p-large-vertical
  padding-top $radar-large-padding
  padding-bottom $radar-large-padding

.r-p-vertical
  padding-top $radar-general-padding
  padding-bottom $radar-general-padding

.r-p-large-all
  padding $radar-large-padding

.r-m-large-all
  padding $radar-large-padding

.r-p-medium-all
  padding $radar-medium-padding

.r-m-medium-all
  padding $radar-medium-padding

.r-p-all
  padding $radar-general-padding

.r-m-all
  padding $radar-general-padding

.icon-link
  color $mq-soft
  font-weight 100
  font-size 16px

a.icon-link
  text-decoration none

  &:visited
    color $mq-soft
    text-decoration: none

.body
  font-size 12px
  font-weight normal
  font-style normal
  font-stretch normal
  line-height 1.43
  letter-spacing 0.24px

.body-2
  @extend .body
  font-size 14px

.body-3
  @extend .body
  font-size 16px

.body-4
  @extend .body
  font-size 10px

.radar-info
  @extend .body
  font-size 16px

.term-title
  @extend $term-title

.term-title-small
  @extend .body-4
  font-size 10px
  font-weight 500
  color $mq-soft

.term-definition
  font-size 14px
  font-weight normal
  font-style normal
  line-height 1.43
  letter-spacing 0.2px
  color black

.underline
  text-decoration underline

.line-height-large
  line-height 1.2rem

.term-title-condensed
  font-size 13px
  color black
  font-weight 400
  letter-spacing: 0.01px

.term-definition-condensed
  font-size 13px
  letter-spacing: 0.01px


.small-field-font,
.q-field.small-field-font
  font-size .9rem

.legend
  font-size 12px
  line-height 1.33
  color $mq-soft

.wrap-text
  overflow-wrap break-word

.all-caps
  text-transform uppercase
