$sticky-header-table
  flex-grow 1
  display flex
  flex-direction column

  .q-table-middle
    flex-grow 1

    &.scroll
      overflow visible

    .q-table.q-table-horizontal-separator
      border-collapse separate
      border-color #aaa

    tbody
      line-height 1.4

    th, td
      padding 5px

    th
      @extend $table-header-text
      position sticky
      top 0
      box-shadow 0 1px 0 0 rgba(0, 0, 0, .2)
      vertical-align middle
      white-space normal
      z-index 901

    td
      padding-top 10px
      overflow hidden
      text-overflow ellipsis

  .q-table-bottom
    position sticky
    bottom 0
    left 0
    background-color #fff
    width 100%
    z-index 901

  .forc-container
    display grid

  // override quasar styling on claim input controls
  td [class^=col-claim] .q-input-target
    justify-content center
    width 100%

  .btn-claim-ok
    margin-left 1px
    width 28px

.sticky-header-table
  display flex
  flex-grow 1

  thead, tbody tr
    display table
    width 100%
    table-layout fixed

  tbody
    display block
    height 100%
    overflow-x hidden
    overflow-y scroll

  td
    overflow hidden
    text-overflow ellipsis

.wrappable-table-column
  white-space normal
  overflow-wrap break-word

$legacy-table-style
  background-color white

  &.fullscreen
    background-color white

    tbody
      min-height calc(100vh - 253px)
      max-height calc(100vh - 253px)

  &.q-table-container
    border-top none

  thead
    border-bottom-width 2px
    box-shadow 0

  .q-table-top
    padding 0
    min-height 0

    .q-table-control
      padding 0

  td:first-child, th:first-child
    padding-left 10px

  td:not(:first-child), th:not(:first-child)
    padding-left 5px
    padding-right 5px

  tr.selected.extra-row-info
    border-top none
    .extra-info-col
      word-wrap break-word
      overflow-wrap break-word

  tr.selected:not(.extra-row-info)
    border-bottom none

  tbody
    min-height calc(100vh - 447px)
    max-height calc(100vh - 447px)

  .q-table-bottom:not(.q-table-nodata)
    @extend .row.reverse
    .q-table-separator
      display: none

.what-if-scenario-table
  @extend $legacy-table-style

  tbody
    min-height calc(100vh - 266px)
    max-height calc(100vh - 266px)

.analytics-overview-table-with-footer
  @extend $legacy-table-style

  tbody
    min-height calc(100vh - 265px)
    max-height calc(100vh - 265px)

  .q-table-bottom
    min-height 28px

.what-if-scenario-review-table
  @extend $legacy-table-style

  tbody
    min-height calc(100vh - 185px)
    max-height calc(100vh - 185px)


.new-sticky-header-table
  @extend $sticky-header-table

.research-income-editor-table
  @extend $sticky-header-table

  // override sort-icon position
  .q-table-sort-icon
    position absolute
    margin-left 70px

  tbody
    td
      &:nth-child(2)
        background-color transparentify($mq-neutral, #fff, .1)

      &:last-child
        background-color transparentify($mq-sand, #fff, .1)


.applied-measures-table
  @extend $sticky-header-table

  tbody
    td
      &:last-child
        background-color transparentify($mq-sand, #fff, .1)

  // override sort-icon position
  .q-table-sort-icon
    position absolute
    margin-top -1em
    margin-left 13em

.portfolio-table
  @extend $sticky-header-table

  thead
    tr
      height 20px;

      &:last-of-type th
        top 35px

    th
      background-color #fff

  tbody td
    vertical-align top

  .portfolio-select
    font-weight 700
    font-size 12px

    &:not(.q-if-disabled)
      .q-input-target-placeholder,
      .q-if-control
        color white !important

.data-quality-table
  @extend $sticky-header-table

  .q-table-middle
    padding-bottom 0

  th
    background-color #fff

  td
    vertical-align top

    &:nth-child(2)
      min-width 90px
      text-align center

    &:nth-child(3)
      min-width 300px
      width clamp(300px, 25vw, 500px)
      white-space normal

    &:nth-child(5)
      min-width 400px
      width clamp(400px, 45vw, 900px)
      white-space normal

    &:nth-child(7), &:last-child
      text-align center

  &.hide-category
    th, td
      &:nth-child(2)
        display none

  &.hide-type
    th, td
      &:nth-last-child(3)
        display none

.xml-table
  @extend $sticky-header-table

  .q-table-middle
    padding-bottom 0

  th
    background-color #fff

  td
    vertical-align top

    &:nth-child(2)
      min-width 90px
      text-align center

    &:nth-child(3)
      min-width 300px
      width clamp(300px, 25vw, 500px)
      white-space normal

    &:nth-child(5)
      min-width 400px
      width clamp(400px, 45vw, 900px)
      white-space normal

    &:nth-child(7), &:last-child
      color $mq-magenta
      text-align center

  &.hide-category
    th, td
      &:nth-child(2)
        display none

  &.hide-type
    th, td
      &:nth-last-child(3)
        display none

.bulk-code-table
  @extend $sticky-header-table
