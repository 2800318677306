$transition-type = .3s ease-out

.dq-dashboard
  overflow hidden
  transition all $transition-type

  &.dq-dashboard--min
    .dq-overview
      padding 0 20px 10px

    .dq-top
      order 1
      margin-top 5px
      font-size 12px

    .r-heading-3
      font-size 0

    .dq-item
      padding 10px 5px 0

    .dq-bar
      padding 0
      height 0
      opacity 0

.dq-overview
  display flex
  flex-direction column
  margin 0 15px 20px
  padding 20px
  background-color white
  transition all $transition-type

.dq-top
  display flex
  align-items center

.dq-panels
  display flex
  flex-wrap wrap
  margin-left -10px
  margin-right -10px

.dq-item
  flex-basis 100%
  padding 10px 5px 0

  @media (min-width $breakpoint-md-min)
    flex-basis 50%
    max-width 50%

  @media (min-width 1024px)
    flex-basis 25%
    max-width 25%

.dq-totals
  span
    padding 0 5px 0 20px

.dq-bar
  display flex
  align-items center
  justify-content space-between
  margin 0 15px 2.5px
  padding 15px
  height 56px
  background-color #fff
  transition height $transition-type, opacity $transition-type

$colorVal($color, $index)
  lookup('$' + $color + '-' + $index)

$setColor($color, $index)
  color $colorVal($color, $index)
  background-color $colorVal($color, 1)
  .dq__icon
    background-color $colorVal($color, $index)
  .dq__item:not(:disabled)
    &:hover
      background-color $colorVal($color, 2)
    &:active
      background-color $colorVal($color, 3)

.dq-stats
  padding 10px
  width 100%
  display grid
  grid-template-columns 80px 3fr 2fr 3fr 3fr
  grid-template-rows 1fr auto
  grid-template-areas \
    "icon title title title title" \
    "icon total -     arc   mq"
  transition all $transition-type

  @media (min-width 1024px) and (max-width 1100px)
    grid-template-columns 3fr 2fr 0 2fr 2fr

  /.dq-dashboard--min &
    padding 5px 10px
    grid-template-columns 2fr 3fr 0 3fr 3fr

  &.dq--blue
    $setColor('blue', 8)

  &.dq--teal
    $setColor('teal', 7)

  &.dq--orange
    $setColor('deep-orange', 10)

  &.dq--indigo
    $setColor('indigo', 6)

.dq__icon
  grid-area icon
  align-self center
  justify-self start
  display flex
  align-items center
  justify-content center
  height 70px
  width 70px
  border-radius 50%
  color white
  font-size 36px
  transition all $transition-type

  @media (min-width 1024px) and (max-width 1100px)
    height 52px
    width 52px
    font-size 28px

  /.dq-dashboard--min &
    height 40px
    width 40px
    font-size 22px

.dq__title
  grid-area title
  margin-top 5px
  font-size 14px
  font-weight 500
  transition height $transition-type, margin $transition-type, opacity .1s ease-out

  /.dq-dashboard--min &
    margin-top 0
    height 0
    opacity 0

.dq__item
  margin-top 5px
  padding 5px
  background-color transparent
  color currentColor
  border 0
  border-radius 4px
  text-align center
  font-size 18px

  @media (min-width 1200px)
    font-size 24px

  /.dq-dashboard--min &
    margin-top 0
    font-size 16px
    font-weight 700

  &::after
    display block
    padding-top 4px
    font-size 12px
    text-transform uppercase

    /.dq-dashboard--min &
      font-size 10px

  &:focus
    outline none

  &:hover
    cursor pointer

.dq__total
  grid-area total
  &::after
    content "total"

.dq__arc
  grid-area arc
  &::after
    content "arc"

.dq__mq
  grid-area mq
  &::after
    content "mq"
