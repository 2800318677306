.am-stats__total {
  color: #000;
  text-decoration: none;
}
.am-stats__total > div {
  padding: 6px 0;
  border-radius: 4px;
}
.am-stats__total > div:not(.no-link):hover {
  background-color: #c5cae9;
  font-weight: 700;
}
.am-stats__total > div.no-link:hover {
  cursor: not-allowed;
}
.am-stats__total > div div:last-child {
  padding-top: 5px;
  color: #c6007e;
}
.am-stats__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
}
.am-stats__total {
  flex-basis: 50px;
  padding: 4px;
}
/*# sourceMappingURL=src/components/researcher/researcher-search/ResearcherAppliedMeasureCount.css.map */