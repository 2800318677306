.analytics-overview-table {
  flex: 1;
  display: flex;
}
.analytics-overview-table >>> table {
  table-layout: fixed;
}
.analytics-overview-table >>> table td:first-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
}
.analytics-overview-table >>> th {
  position: sticky;
  top: 0;
  padding-bottom: 10px !important;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  background-color: #fff;
  z-index: 901;
}
.analytics-overview-table >>> th.col-category span {
  font-family: 'Roboto Condensed', sans-serif;
}
.col-category {
  padding: 0;
  min-width: 90px;
}
.col-category:nth-of-type(even) {
  background-color: #eceff8;
}
.cat-label {
  padding: 2px 0;
  background-color: rgba(107,124,147,0.55);
}
/*# sourceMappingURL=src/components/analytics/overview/PeerReviewOverviewTable.css.map */