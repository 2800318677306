@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'



















































































































































































































































































































































































.analytics-overview-table
  flex: 1;
  display: flex;

  >>> table
    table-layout fixed

    td:first-of-type
      overflow hidden
      text-overflow ellipsis

  >>> th
    position sticky
    top 0
    padding-bottom 10px !important
    border-bottom 1px solid rgba(0,0,0,.2)
    background-color white
    z-index 901

    &.col-category span
      font-family 'Roboto Condensed', sans-serif

.col-category
  padding 0
  min-width 90px

  &:nth-of-type(even)
    background-color $mq-neutral

.cat-label
  padding 2px 0
  background-color rgba($mq-soft-muted, .55)
