@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'



































































.researcher-banner
  background -webkit-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background -o-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background -ms-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background -moz-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
  background linear-gradient(to right, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
  rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)

.search-researcher-link
  text-decoration none

  .q-chip:hover
      transform scale(1.05)
