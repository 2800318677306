@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'





















































































































































































































































































































































































.portfolio-select
  height 30px
  font-size 11px
  font-weight 400
  text-transform none
  letter-spacing 0

.header-top-section
  height 40px

.col-toggle
  width 30px
  text-align right

.col-action
  .wrappable-table-column
    min-width 70px
    max-width 115px
    margin-top -8px

.col-pure-id
  width 100px

.col-portfolio
  min-width 300px

.col-claim
  min-width 150px
  background-color transparentify($mq-sand, #fff, .1)

.claim-container
  display flex
  justify-content center
  max-width 110px
  margin 0 auto
  text-align right

  div:nth-of-type(2)
    flex-grow 1

  div:last-of-type
    min-width 20px

.ro-container
  display flex
  min-width 190px

.ro-type
  min-width 20px
  text-align center
  margin-right 10px
  line-height 1.5

.finalised
  background-color rgba(190, 120, 120, 0.1) !important

.valid
  background-color #659d4e

.under
  background-color #066ba0

.over
  background-color #802243

.claim_count_error
  background-color $primary

.no_claims
  background-color #a79c78

.portfolio_member
  background-color #606a8b

.ineligible
  background-color #4e4e4e

.clawback_invalid
    background-color #cd4646

.ro-title
  text-align justify

a:link
  &.javascript-link
    color: $dark
    text-decoration none

    &:hover
      text-decoration underline

a:visited
  color $blue

  &:hover
    text-decoration underline

