.search-bar {
  border-left: 1px solid #f4f2ef;
}
.mini-search-title {
  word-break: break-all;
  width: 1em;
}
.mini {
  width: 60px;
}
.full {
  width: 400px;
}
.container {
  margin-top: 6rem;
}
/*# sourceMappingURL=src/components/user/UsersSearch.css.map */