.search-bar {
  flex-basis: 250px;
}
.grid-cell {
  padding: 5px 0;
  vertical-align: top;
}
.ineligible {
  background-color: #eee;
  color: #888;
}
.ineligible .claim-btn {
  display: none;
}
.ineligible-tooltip {
  background-color: #76232f;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
.applied-measures-table >>> th.grid-cell {
  vertical-align: top;
  padding-top: 20px;
}
.detail-section,
.claim-section {
  position: relative;
  display: grid;
}
.detail-section > div,
.claim-section > div {
  padding: 0 5px;
}
.detail-section {
  grid-template-columns: 25px 1fr 100px 50px 135px;
  grid-template-areas: "toggle title    am-type  year     autoforc" "toggle authors  authors  year     autoforc" "toggle children children children children";
}
.col-toggle {
  grid-area: toggle;
  position: absolute;
  margin-top: -2px;
  padding-left: 0;
}
.col-title {
  grid-area: title;
  min-width: 180px;
  white-space: normal;
}
.col-authors {
  grid-area: authors;
}
.col-am-type {
  grid-area: am-type;
}
.col-year {
  grid-area: year;
}
.col-autoforc {
  grid-area: autoforc;
  text-align: right;
}
.col-autoforc span {
  display: inline-block;
  width: 50px;
}
.col-info {
  grid-area: info;
}
.col-children {
  grid-area: children;
}
.claim-section {
  min-width: 470px;
  grid-template-columns: 1fr 1fr 1fr 40px;
  grid-template-rows: 1fr auto;
  grid-template-areas: "claim1     claim2     claim3     status" "claim-info claim-info claim-info status";
}
.claim-section [class^=col-claim] {
  min-width: 140px;
  text-align: center;
}
.col-claim1 {
  grid-area: claim1;
}
.col-claim2 {
  grid-area: claim2;
}
.col-claim3 {
  grid-area: claim3;
}
.col-status {
  grid-area: status;
  padding-top: 2px;
}
.col-claim-info {
  grid-area: claim-info;
}
/*# sourceMappingURL=src/components/applied-measure/AppliedMeasureList.css.map */