.report-table {
  min-width: max-content;
}
.report-table >>> table {
  position: relative;
}
.report-table >>> .scroll {
  overflow: visible;
}
.report-table >>> th,
.report-table >>> td {
  padding: 2px 10px;
  border-color: #ccc;
}
@media (min-width: 768px) {
  .report-table >>> th,
  .report-table >>> td {
    height: 40px;
  }
}
.report-table >>> thead tr {
  height: 40px;
}
.report-table >>> thead th {
  position: sticky;
  top: 0;
  border-left-width: 1px;
  background-color: #ece8dc;
  color: #222;
  font-size: 13px;
  text-transform: uppercase;
  box-shadow: 0 2px 2px -1px rgba(0,0,0,0.4);
  z-index: 1;
}
.report-table >>> thead th:first-child {
  border-left-width: 0;
}
.collapsible-report .report-table >>> thead th {
  top: 65px;
}
.q-collapsible-opened div[style^=overflow-y] .report-table >>> thead th,
.q-collapsible-closed .report-table >>> thead th {
  top: auto;
}
.report-table >>> tbody tr:nth-child(odd) td {
  background-color: rgba(0,0,0,0.05);
}
.report-table >>> tbody tr td:empty {
  background-color: #aaa;
}
.report-table.has-summary-row >>> tbody tr:last-child td {
  font-weight: 700;
}
.empty-table-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
/*# sourceMappingURL=src/components/reporting/common/ReportTable.css.map */