@import '../styles/variables/typography-vars.styl'
@import '../styles/variables/mq-colors.styl'
@import '../styles/variables/quasar-color-overrides.styl'
@import '../styles/variables/quasar.variables.styl'
@import '../styles/variables/app.variables.styl'

@import '../styles/variables/extends.styl'






































































































































.home-panel
  display flex
  justify-content space-between
  align-items center
  padding 10px 25px
  background linear-gradient(90deg, #eee 0%, $mq-soft-muted 70%)

.mq-logo
  width clamp(50px, 80vw, 300px)
  height 100%

.radar-logo
  width 120px
  color #fff
  cursor pointer

.home__announcements
  background-color rgba($primary, 20%)
  border 2px solid rgba($primary, 20%)
  border-radius 6px

  span
    color $secondary

.home__section
  box-shadow none

  & + &
    margin-top 30px

  >>> .q-card-title
    color #000
    font-size $font-size-6

  >>> .q-item,
  >>> .q-card-main
    color $blue-grey-7
    font-size $font-size-4

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
