.editor {
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 6px;
  overflow: hidden;
}
.editor__content {
  padding: 20px 10px;
  background-color: rgba(255,255,255,0.3);
}
.editor__content >>> .ProseMirror:focus {
  outline: none;
}
.editor__header {
  padding: 5px;
  border-bottom: 1px solid rgba(0,0,0,0.4);
}
.editor__header > * + * {
  margin-left: 5px;
}
.editor__footer {
  padding: 10px;
  border-top: 1px solid rgba(0,0,0,0.4);
}
.editor__footer > * + * {
  margin-left: 10px;
}
.is-active {
  background-color: rgba(0,0,0,0.1);
}
/*# sourceMappingURL=src/components/common/RichTextEditor.css.map */