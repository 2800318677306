$typography-font-family = 'Roboto', '-apple-system', 'Helvetica Neue', Helvetica, Arial, sans-serif

$font-size-1 = 10px
$font-size-2 = 12px
$font-size-3 = 13px
$font-size-4 = 14px
$font-size-5 = 16px
$font-size-6 = 20px
$font-size-7 = 24px
$font-size-8 = 30px

$h1
  margin 0
  font-size $font-size-7
  font-weight 400
  letter-spacing 0

$h2
  margin 0
  font-size $font-size-6
  font-weight 400
  letter-spacing 0

$h3
  margin 0
  font-size $font-size-6
  font-weight 400
  letter-spacing .25px

$body-text
  font-size $font-size-4
  font-weight 400
  letter-spacing .25px

$table-header-text
  font-size $font-size-2
  font-weight 500
  letter-spacing  .25px
  text-transform uppercase

$table-text
  font-size $font-size-3
  font-weight 400
  letter-spacing 0

$button-text
  font-size $font-size-2
  font-weight 500
  letter-spacing 0

$status-text
  font-size $font-size-2
  font-weight 400
  letter-spacing 0

$label-text
  font-size $font-size-1
  font-weight 500
  letter-spacing .25px
  text-transform uppercase
