@import '../../styles/variables/typography-vars.styl'
@import '../../styles/variables/mq-colors.styl'
@import '../../styles/variables/quasar-color-overrides.styl'
@import '../../styles/variables/quasar.variables.styl'
@import '../../styles/variables/app.variables.styl'

@import '../../styles/variables/extends.styl'



















































































































































































































































































.search-bar
  width 15rem

.pagination-total
  padding 0 15px
  height 48px
  background-color #fff
  color $primary

.xml-notification
  @extend $status-text
  margin 10px 6px 0
  padding 5px
  width 100%
  background-color $yellow-2
  color rgba(0, 0, 0, .55)
  border 1px solid rgba(0, 0, 0, .2)
  border-radius 4px

@keyframes ellipsis
  to
    width 1.25em

.loading:after
  overflow hidden
  display inline-block
  vertical-align bottom
  animation ellipsis steps(4, end) 900ms infinite
  content: "\2026"; /* ascii code for the ellipsis character */
  width 0

.xml-summary-table
  >>> th
    @extend $table-header-text
    background-color $mq-sand-dark
