@import '../../../styles/variables/typography-vars.styl'
@import '../../../styles/variables/mq-colors.styl'
@import '../../../styles/variables/quasar-color-overrides.styl'
@import '../../../styles/variables/quasar.variables.styl'
@import '../../../styles/variables/app.variables.styl'

@import '../../../styles/variables/extends.styl'
























































































































































































































































































































































































$indicator-color = #80235f
$icon-link
  color $mq-purple
  font-size: 14px
  font-weight: normal
  font-style: normal
  line-height: 1.43;
  letter-spacing: 0.2px;

a.icon-link
  @extend $icon-link
  text-decoration none
  &:visited
    color $mq-purple
    text-decoration: none

.affiliations
  margin-left 2.2rem

.author-name
  text-decoration none
  line-height 1.1rem

.abstract
  text-decoration underline

.q-collapsible
  .term-definition
    margin-bottom 24px

.claim-status
  font-size 20px
  line-height 1.3
  color $secondary
  text-transform capitalize

.dispute-status
  font-size 20px
  font-weight normal
  line-height 1.4
  color $mq-soft

  text-transform capitalize

.forc
  font-size 16px
  font-weight normal
  font-style normal
  line-height 1.25
  letter-spacing 0.3px
  color $mq-soft-muted

.apportion
  font-size 14px
  font-weight normal
  font-style normal
  line-height 1.43
  letter-spacing 0.2px
  color black
  word-break keep-all

.icon-highlight-alert > .q-alert > .q-alert-side > .q-icon
  color $negative

.sensitivity::v-deep .q-dot
  background $indicator-color
