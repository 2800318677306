.ribbon
  .triangle-bottom-right
    width 0
    height 0
    border-bottom 25px solid $primary
    border-left 25px solid transparent

  .triangle-top-left
    width 0
    height 0
    border-top 25px solid $primary
    border-right 25px solid transparent

  .triangle-bottom-right-white
    width 0
    height 0
    border-bottom 25px solid $mq-white
    border-left 25px solid transparent

  .triangle-top-left-white
    width 0
    height 0
    border-top 25px solid $mq-white
    border-right 25px solid transparent

.ribbon.ribbon-primary
  .triangle-bottom-right
    border-bottom-color: $primary !important
  .triangle-top-left
    border-top-color: $primary !important

.ribbon.ribbon-secondary
  .triangle-bottom-right
    border-bottom-color: $secondary !important
  .triangle-top-left
    border-top-color: $secondary !important

for $mqColor in $mqColors
  .ribbon.ribbon-{$mqColor}
    .triangle-bottom-right
      border-bottom-color: lookup('$' + $mqColor) !important
    .triangle-top-left
      border-top-color: lookup('$' + $mqColor) !important
