$tall-height = 2.25rem

.comfortable-textarea
  padding 14px !important

.search-bar
  border 1px solid $mq-grey-dark
  border-radius 3px !important
  width 24rem
  background-color white

  i
    color black
    padding 0 6px


.filter-bar-dense
  border 1px solid $mq-grey-dark
  border-radius 3px !important
  background-color white

  i
    &+div
      padding-left 6px

.filter-bar
  @extend .filter-bar-dense
  padding 6px


//.q-checkbox.q-option.reverse.no-margin-label
//    span.q-option-label
//      margin-right 0 !important

.q-input.with-control-on-right
  .q-if-inner > .row
    max-width 100%
    padding-right 10px

.q-field.text-white
  .q-field-label
    color white !important

    &.text-primary
      color white !important

  .q-field-content
    color white !important

    .text-primary
      color white !important

.q-field.error-white
  .q-field-error
    &:before
      content '* '
      color $negative
    color white !important

    &.text-primary
      color white !important

  .q-field-content
    color white !important

    .text-primary
      color white !important

.q-field.items-baseline.q-field-horizontal
  .row
    @extend .items-baseline

.q-field.small-label, .q-checkbox.small-label, .q-radio.small-label, .q-toggle.small-label
  font-size 12px !important

.q-field.content-bg-white
  .q-field-content
    background-color white !important
    border-radius $generic-border-radius !important
    padding-top $radar-small-padding

  &.no-padding-top
    .q-field-content
      padding-top 0

for $mqColor in $mqColors
  .text-{$mqColor}
    .q-field
      .q-field-label
        color lookup('$' +$mqColor)

.q-field
  font-size 16px
  font-weight normal
  font-style normal
  line-height 1.25
  letter-spacing 0.3px
  text-align left

  .q-if-label, .q-if-addon, .q-if-control, .floating-label-font, .q-field-helper, .q-field-label
    color $mq-soft !important

  .q-if-standard
    padding-bottom 0

.wrap-label-break
  .q-field-label
    .q-field-label-inner
      word-break break-all;

.q-field-group
  .q-field
    .q-field-content
      .q-if
        padding-left 0
        padding-right 0
        border-radius: 0

  .q-field:first-child
    .q-field-content
      .q-if
        border-radius: $generic-border-radius 0 0 $generic-border-radius

  .q-field:last-child
    .q-field-content
      .q-if
        border-radius: 0 $generic-border-radius $generic-border-radius 0

.modal-content-wrapper
  min-width 70vw
  max-width 90vw
  height 90vh
  overflow-y: scroll
  padding $radar-general-padding $radar-large-padding

.floating-label-font
  @extend .q-if-label
  font-size ($input-font-size * $input-label-top-scale)
  line-height $input-control-size * $input-label-top-scale

.q-field-bottom
  .text-transparent
    display: none

.q-field-no-input:not(.q-field-with-error)
  .q-field-bottom
    margin-top 0
    border-top none

.q-select > i.q-icon.q-if-control.material-icons,
.q-input > i.q-icon.q-if-control.material-icons
  z-index: $z-form-top

.q-select > i.q-icon:first-of-type
  order 9

.q-select
  & .q-input-target
    color inherit

  .q-input-chips
    margin-bottom 2px

.tall-input
  font-size 1rem
  height $tall-height

  .q-input-target
    padding .5rem
    height: 21px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;

.white-text-input
  input.q-input-target, textarea.q-input-target
    @extend .text-white

.center-text-area
  textarea.col.q-input-target.q-input-area
    text-align: center

.q-input.light-bg
  background-color $grey-3

for $mqColor in $mqColors
  .field-text-{$mqColor}
    .q-if-label, .q-if-addon, .q-if-control, .floating-label-font, .q-field-helper
      color lookup('$' +$mqColor) !important


// Buttons

.tall-button
  height: $tall-height
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

.search-input
  width 30rem

.q-tabs.head-bg-transparent
  .q-tabs-head
    background transparent
