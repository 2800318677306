.app-header {
  background-color: #f4f2ef !important;
  z-index: 1000 !important;
}
.app-footer {
  background-color: #fff;
  border-top: 1px solid #ccc;
  box-shadow: none;
  z-index: 1000;
}
/*# sourceMappingURL=src/layouts/Default.css.map */